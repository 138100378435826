let menu = [
  {
    title: "داشبورد",
    icon: "fa-home",
    to: "/super-admin",
    children: [],
  },
  {
    title: "شرکت ها",
    icon: "fa-building",
    to: "/super-admin/companies",
    children: [],
  },
  {
    title: "مدارس",
    icon: "fa-school",
    to: "",
    children: [
      {
        title: "شیفت",
        icon: "",
        to: "/super-admin/schools/shift",
        children: [],
      },
      {
        title: "انواع",
        icon: "",
        to: "/super-admin/schools/types",
        children: [],
      },
      {
        title: "مقطع",
        icon: "",
        to: "/super-admin/schools/grade",
        children: [],
      },
      {
        title: "مدارس",
        icon: "",
        to: "/super-admin/schools/school",
        children: [],
      },
    ],
  },
  {
    title: "رانندگان",
    icon: "fa-car",
    to: "",
    children: [
      {
        title: "راننده",
        icon: "",
        to: "/super-admin/drivers/driver",
        children: [],
      },
    ],
  },
  {
    title: "تماس باما",
    icon: "fa-envelope-open-text",
    to: "/super-admin/contacts",
    children: [],
  },
  {
    title: "اسپانسر ها",
    icon: "fa-handshake",
    to: "/super-admin/sponsors",
    children: [],
  },
];

menu.forEach((item) => {
  if (item.children) {
    return true;
  }
});
export default menu;
