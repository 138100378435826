import superAdminMenus from "@@/core/components/superAdmin/sidebar/menu.js";
import companiesAdminMenus from "@@/core/components/companies/sidebar/menu.js";
export default {
  settingGroups: null,
  loading: false,
  superAdminMenus: superAdminMenus,
  companiesAdminMenus: companiesAdminMenus,
  permissions: null,
  admin: null,
  notifications: null,
  total_unread_notifications: 0,
  contacts: null,
  total_unread_contacts: 0,
};
