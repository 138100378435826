export default [
  //===== super admin routes =====
  {
    path: "/super-admin",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/index/Index"
      ),
    meta: {
      title: "پنل مدیریت ارشد",
    },
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/dashboard/Dashboard"
          ),
        meta: {
          title: "پنل مدیریت ارشد",
        },
      },
      {
        path: "/super-admin/schools/school",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/schools/school/School"
          ),
        meta: {
          title: "مدارس",
        },
      },
      {
        path: "/super-admin/schools/school/edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/schools/school/SchoolEdit"
          ),
        meta: {
          title: "مدارس",
        },
      },
      {
        path: "/super-admin/schools/school/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/schools/school/SchoolEdit"
          ),
        meta: {
          title: "مدارس",
        },
      },
      {
        path: "/super-admin/schools/types",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/schools/types/Types"
          ),
        meta: {
          title: "انواع مدارس",
        },
      },
      {
        path: "/super-admin/schools/shift",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/schools/shift/Shift"
          ),
        meta: {
          title: "شیفت مدارس",
        },
      },
      {
        path: "/super-admin/schools/grade",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/schools/grade/Grade"
          ),
        meta: {
          title: "مقاطع مدارس",
        },
      },
      {
        path: "/super-admin/drivers/driver",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/drivers/driver/Driver.vue"
          ),
        meta: {
          title: "راننده",
        },
      },
      {
        path: "/super-admin/drivers/driver/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/drivers/driver/DriverEdit.vue"
          ),
        meta: {
          title: "راننده",
        },
      },
      {
        path: "/super-admin/companies",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/companies/Companies"
          ),
        meta: {
          title: "شرکت ها",
        },
      },
      {
        path: "/super-admin/companies/edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/companies/CreateEdit"
          ),
        meta: {
          title: "ویرایش شرکت",
        },
      },
      {
        path: "/super-admin/companies/create",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/companies/CreateEdit"
          ),
        meta: {
          title: "شرکت جدید",
        },
      },
      {
        path: "/super-admin/companies/schools/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/companies/AddSchools"
          ),
        meta: {
          title: "مدارس",
        },
      },
      {
        path: "/super-admin/contacts",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/contact/Index"
          ),
        meta: {
          title: "تماس باما",
        },
      },
      {
        path: "/super-admin/contact/edit/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/contact/ShowEdit"
          ),
        meta: {
          title: "تماس باما",
        },
      },
      {
        path: "/super-admin/sponsors",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/sponsor/Sponsor"
          ),
        meta: {
          title: "اسپانسر ها",
        },
      },
      {
        path: "/super-admin/settings/:group",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/settings/Settings"
          ),
        meta: {
          title: "تنظیمات",
        },
      },
      {
        path: "/super-admin/store/settings",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/settings/storeSettings.vue"
          ),
        meta: {
          title: "تنظیمات",
        },
      },
    ],
  },
  {
    path: "/super-admin/login",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@@/core/pages/superAdmin/auth/login/Login.vue"
      ),
    meta: {
      title: "ورود به پنل مدیر ارشد",
    },
  },

  /*===== companies routes =====*/
  {
    path: "/companies-admin",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@@/core/pages/companies/index/Index"
      ),
    meta: {
      title: "پنل شرکت ها",
    },
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/companies/dashboard/Dashboard"
          ),
        meta: {
          title: "پنل شرکت ها",
        },
      },
      {
        path: "/companies-admin/drivers",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/companies/drivers/Drivers"
          ),
        meta: {
          title: "رانندگان",
        },
      },
      {
        path: "/companies-admin/contract",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/companies/contract/Contracts"
          ),
        meta: {
          title: "قرارداد ها",
        },
      },
      {
        path: "/companies-admin/contract/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/companies/contract/Contract"
          ),
        meta: {
          title: "قرارداد",
        },
      },
      {
        path: "/companies-admin/contract/payment/:id",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/companies/contract/Payment"
          ),
        meta: {
          title: "مدیریت مالی و پرداخت",
        },
      },
      {
        path: "/companies-admin/school/schools",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/companies/school/Schools"
          ),
        meta: {
          title: "مدارس",
        },
      },
      {
        path: "/companies-admin/driver-registration",
        component: () =>
          import(
            /* webpackChunkName: "admin" */ "@@/core/pages/companies/drivers/Registration"
          ),
        meta: {
          title: "درخواست عضویت راننده ها",
        },
      },
    ],
  },
  {
    path: "/companies-admin/login",
    component: () =>
      import(
        /* webpackChunkName: "admin" */ "@@/core/pages/companies/auth/login/Login.vue"
      ),
    meta: {
      title: "ورود به پنل شرکت ",
    },
  },
];
