import { getCookie } from "@@/core/libraries/VueMethods";
export default {
  initialized: (state) => {
    return Boolean(state["admin/settings"]);
  },
  getPermissions: (state) => {
    return state.permissions
      ? state.permissions
      : getCookie("permissions")
      ? JSON.parse(getCookie("permissions"))
      : null;
  },
};
