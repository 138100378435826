let menu = [
  {
    title: "داشبورد",
    icon: "fa-home",
    to: "/companies-admin",
    children: [],
  },
  {
    title: "رانندگان",
    icon: "fa-car",
    to: "/companies-admin/drivers",
    children: [],
  },
  {
    title: "قراردادها",
    icon: "fa-clipboard-list",
    to: "/companies-admin/contract",
    children: [],
  },
  {
    title: "مدارس",
    icon: "fa-school",
    to: "/companies-admin/school/schools",
    children: [],
  },
  {
    title: "درخواست ها",
    icon: "fa-address-card",
    to: "/companies-admin/driver-registration",
    children: [],
  },
];

menu.forEach((item) => {
  if (item.children) {
    return true;
  }
});
export default menu;
