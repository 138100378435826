import axios from "axios";

export default {
  async loadInitData({ commit, state }) {
    // const response = await axios.get('admin/home')
    // commit('setSettingGroups', response.data.data.setting_groups);
    // let sG = response.data.data.setting_groups;
    // sG.forEach((g) => {
    //   g.title = g.label
    //   delete g.label
    //   g.to = '/admin/settings/' + g.name
    //   g.children = []
    //   g.permissions = ['public']
    // })
    // commit('addToMenu', {title:'تنظیمات' , icon:'fe fe-sliders', to:'' , children: sG , permissions:['read_setting']} );
  },
  async getSettingsFromserver({ commit }) {
    await axios
      .get("admin/settings")
      .then((response) => {
        let sG = response.data.data.groups;
        sG.forEach((g) => {
          g.title = g.name;
          g.to = "/super-admin/settings/" + g.name;
          g.children = [];
        });
        commit("addToSuperAdminMenusMenu", {
          title: "تنظیمات",
          icon: "fa-cogs",
          to: "",
          children: sG,
        });
      })
      .catch((error) => {
        console.log(error);
      })
  },

  showLoading({ commit, state }, onlyWhenNotInitialized = true) {
    if (onlyWhenNotInitialized && this.getters.initialized) {
      return;
    }
    commit("incrementLoading");
  },
  hideLoading({ commit, state }, onlyWhenNotInitialized = true) {
    if (
      onlyWhenNotInitialized &&
      this.getters.initialized &&
      state.loading === 0
    ) {
      return;
    }
    commit("decrementLoading");
  },
};
