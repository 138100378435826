<template>
  <div id="app">
    <MediaQueryProvider :queries="$options.queries">
      <MatchMedia v-slot="{ mobileSize }">
        <notifications
          group="error"
          :position="!mobileSize ? 'top right' : 'top center'"
        />
        <notifications
          group="success"
          :position="!mobileSize ? 'top right' : 'top center'"
        />
        <notifications group="warning" position="top center" />
      </MatchMedia>
      <router-view></router-view>
      <transition name="fade">
        <div class="loading-spinner" v-if="$store.state.loading">
          <div
            class="loading-spinner-div"
            style="margin-top: auto; height: 100%"
          >
            <fulfilling-bouncing-circle-spinner
              :animation-duration="4000"
              :size="60"
              :color="colorLoading"
            />
          </div>
        </div>
      </transition>
    </MediaQueryProvider>
  </div>
</template>
<script>
// افزودن کامپوننت زیر برای  حالت موبایلی و استفاده از آن در کامپوننت های دیگر
import { MediaQueryProvider } from "vue-component-media-queries";
import { MatchMedia } from "vue-component-media-queries";
import config from "@/config";
import defaultConfig from "@@/./config";

const queries = {
  mobileSize: "(max-width: 768px)",
};
export default {
  queries,
  components: {
    MediaQueryProvider,
    MatchMedia,
  },
  data() {
    return {
      colorLoading: config.colorLoading || defaultConfig.colorLoading,
    };
  },
};
</script>
