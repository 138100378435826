import Vue from "vue";
import dayjs from "dayjs";

Vue.filter("price", function (num) {
  if (num) {
    return num
      .toString()
      .replace(/\D/g, "")
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  } else {
    return 0;
  }
});

Vue.filter("dateTime", function (date) {
  return dayjs(date).locale("fa").format("HH:mm YYYY/MM/DD");
});

Vue.filter("date", function (date) {
  return dayjs(date).locale("fa").format("YYYY/MM/DD");
});
