import { setCookie } from "@@/core/libraries/VueMethods";

export default {
  setSettingGroups(state, settingGroups) {
    state.settingGroups = settingGroups;
  },
  setAdmin(state, admin) {
    state.admin = admin;
  },
  setNotifications(state, notifications) {
    state.notifications = notifications;
  },
  setContacts(state, contacts) {
    state.contacts = contacts;
  },
  setTotalUnreadContacts(state, total_unread_contacts) {
    state.total_unread_contacts = total_unread_contacts;
    if (total_unread_contacts === 0 && state.contacts) {
      state.contacts = state.contacts.map((contact) => {
        contact.status = 1;
        return contact;
      });
    }
  },
  setTotalUnreadNotifications(state, total_unread_notifications) {
    state.total_unread_notifications = total_unread_notifications;
    if (total_unread_notifications === 0 && state.notifications) {
      state.notifications = state.notifications.map((noty) => {
        noty.read_at = new Date();
        return noty;
      });
    }
  },
  incrementLoading(state) {
    state.loading++;
  },
  decrementLoading(state) {
    if (state.loading > 0) {
      state.loading--;
    }
  },
  setSuperAdminMenus(state, menus) {
    state.superAdminMenus = menus;
  },
  addToSuperAdminMenusMenu(state, menu) {
    if (state.superAdminMenus.filter((el) => el.title.includes("تنظیمات")) == "") {
      state.superAdminMenus.push(menu);
    }
  },
  setCompaniesAdminMenus(state, menus) {
    state.companiesAdminMenus = menus;
  },
  addToCompaniesAdminMenu(state, menu) {
    if (state.companiesAdminMenus.filter((el) => el.title.includes("تنظیمات")) == "") {
      state.companiesAdminMenus.push(menu);
    }
  },
  setPermissions(state, permissions) {
    setCookie("permissions", JSON.stringify(permissions));
    state.permissions = permissions;
  },
  loadMoreNotifications(state, newNotifications) {
    state.notifications = [...state.notifications, ...newNotifications];
  },
};
